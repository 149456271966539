import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SVG from 'react-inlinesvg';
import { orderBy } from 'lodash';

import DataTestimonials from '../data/testimonials.json';
import Layout from '../components/Layout';
import ReferenceSwiper from '../components/ReferenceSwiper';
import Blog from '../components/sections/Blog';
import Counter from '../components/Counter';
import BackgroundHeroSrc from '../img/svg/pageTraining/hero_bg.svg';
import BackgroundDetailsTopSrc from '../img/svg/pageHome/company_bg-top.svg';
import BackgroundDetailsBottomSrc from '../img/svg/pageHome/company_bg-bottom.svg';
import Bars3Src from '../img/svg/bars-3.svg';
import Bars4Src from '../img/svg/bars-4.svg';
import BackgroundClientsSrc from '../img/svg/pageHome/conference_bg.svg';

import Clients from '../components/sections/Clients';
import WorkshopItem from '../components/WorkshopItem';
import Depiction from '../components/Depiction';
import Experience from '../components/sections/Experience';

export const TrainingPageTemplate = ({
  hero,
  details,
  workshops,
  features,
  blogs,
  testimonials,
  testimonialsTraining,
  experience
}) => (
  <>
    <section className="TrainingHero">
      <div className="TrainingHero__background">
        <SVG
          src={BackgroundHeroSrc}
          uniquifyIDs={false}
          className="TrainingHero__background-image"
        />
      </div>

      <div className="TrainingHero__container">
        <div className="TrainingHero__content">
          <h1 className="TrainingHero__title">{hero.title}</h1>

          <p className="TrainingHero__text">{hero.text}</p>
        </div>
      </div>
    </section>

    <section className="TrainingDetails">
      <SVG
        src={BackgroundDetailsTopSrc}
        className="TrainingDetails__background"
      />
      <SVG
        src={BackgroundDetailsBottomSrc}
        className="TrainingDetails__background TrainingDetails__background--bottom"
      />

      <div className="TrainingDetails__container">
        <ul className="TrainingDetails__items">
          {details.counters.map((item, i) => (
            <Counter
              key={i}
              tagName="li"
              className={`TrainingDetails__counter TrainingDetails__counter--${i}`}
              variants={['on-background', 'small']}
              {...item}
            />
          ))}
        </ul>

        {details.photos.map((item, i) => (
          <img
            key={i}
            src={'/img/' + item.image.relativePath}
            className={`TrainingDetails__photo TrainingDetails__photo--${i}`}
            style={{'position': 'absolute'}}
            alt={item.name}
          />
        ))}

        <p className="TrainingDetails__contact">
          <label className="TrainingDetails__contact-label">
            {details.contact.label}
          </label>
          <br />
          <a href={`mailto: ${details.contact.email}`}>
            {details.contact.email}
          </a>
        </p>

        <SVG
          src={Bars3Src}
          className="TrainingDetails__bars TrainingDetails__bars--1"
        />

        <SVG
          src={Bars4Src}
          className="TrainingDetails__bars TrainingDetails__bars--2"
        />
      </div>
    </section>

    <section className="TrainingItems">
      <div className="TrainingItems__background">
        <SVG
          src={BackgroundClientsSrc}
          uniquifyIDs={false}
          className="TrainingItems__background-image"
        />
        <div className="TrainingItems__background-layer"></div>
      </div>

      <div className="TrainingItems__container">
        <div className="TrainingItems__clients">
          <Clients />
        </div>

        <h2 className="TrainingItems__title">{workshops.heading}</h2>

        <div className="TrainingItems__text">
          {workshops.text}
        </div>

        <ul className="TrainingItems__items">
          {workshops.items.map((item, i) => (
            <WorkshopItem
              key={i}
              tagName="li"
              iconSrc={item.image.publicURL || item.image}
              title={item.title}
              url={item.slug}
              isNewDate={item.is_new_date}
            >
              {item.description}
            </WorkshopItem>
          ))}
        </ul>
      </div>
    </section>

    <section className="Training__feature">
      <div className="Training__container">
          <h2 className="Training__feature__title">
            What Big Data training can offer your business?
          </h2>
          <Depiction
            headingLevel={3}
            features={features}
            variants={['left']}
          >
          </Depiction>
      </div>
    </section>

    <section className="TrainingTestimonials">
      <div className="TrainingTestimonials__background">
        <SVG
          src={BackgroundClientsSrc}
          uniquifyIDs={false}
          className="TrainingTestimonials__background-image"
        />
      </div>
      <div className="TrainingTestimonials__container">
        <h2 className="TrainingTestimonials__title">{testimonialsTraining.heading}</h2>

        <div className="TrainingTestimonials__text">{testimonialsTraining.text}</div>

        <ReferenceSwiper data={DataTestimonials.workshops} />
      </div>
    </section>

    {/* <Blog 
      className="Training__blog" 
      data={blogs}
    /> */}
    <Experience 
      heading={experience.heading}
      items={experience.items}
    />
  </>
);

const TrainingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const dataWorkshops = data.workshops.edges;

  const dataWorkshopsFormatted = dataWorkshops.map(function(elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;

    return {
      ...elem.node.frontmatter,
      slug: slug // should be last 
    };
  });

  let dataBlogs = data.blogs.edges.map(function(elem, i) {
    let slugFrontmatter = elem.node.frontmatter.slug;
    let slug = slugFrontmatter ? slugFrontmatter : elem.node.fields.slug;
    
    return {
      ...{id: elem.node.id}, 
      ...{excerpt: elem.node.excerpt}, 
      ...elem.node.frontmatter,
      slug: slug // should be last 
    };
  });

  // order by 'order' and 'date' fields
  dataBlogs = orderBy(dataBlogs, [
    (item) => (item.order),
    (item) => (item.date)
  ], ["asc", "desc"]);

  const mergedWorkshops = {
    ...frontmatter.workshops, 
    ...{items: dataWorkshopsFormatted}
  };
  
  const mergedData = {
    ...frontmatter, 
    ...{workshops: mergedWorkshops}, 
    ...{blogs: dataBlogs}
  };

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <TrainingPageTemplate {...mergedData} />
    </Layout>
  );
};

TrainingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TrainingPage;

export const trainingPageQuery = graphql`query TrainingPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "big-data-training-page"}}) {
    frontmatter {
      meta {
        title
        description
        image {
          publicURL
          name
        }
      }
      hero {
        title
        text
      }
      details {
        photos {
          image {
            relativePath
            name
          }
          alt
        }
        contact {
          label
          email
        }
        counters {
          amount
          isPrecise
          description
        }
      }
      workshops {
        heading
        text
      }
      experience {
        heading
        items {
          text
          title
          icon {
            publicURL
            name
          }
          slug
        }
      }
      testimonialsTraining {
        heading
        text
      }
      features {
        name
        text
      }
    }
  }
  workshops: allMarkdownRemark(
    limit: 6
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "workshop-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 400)
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredWorkshop
          slug
          image {
            publicURL
            name
          }
          is_new_date
        }
      }
    }
  }
  blogs: allMarkdownRemark(
    limit: 6
    sort: {frontmatter: {order: ASC}}
    filter: {frontmatter: {templateKey: {eq: "blog-post"}, published: {ne: false}}}
  ) {
    edges {
      node {
        excerpt(pruneLength: 150)
        id
        fields {
          slug
        }
        frontmatter {
          order
          date
          title
          description
          slug
          image {
            publicURL
            name
          }
        }
      }
    }
  }
}`;
