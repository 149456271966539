import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateImgAlt from '../helpers/generateImgAlt';

const propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  variants: PropTypes.arrayOf(PropTypes.oneOf(['left', 'center', 'right'])),
};

const defaultProps = {
  text: '',
  tagName: 'div',
  className: '',
  headingLevel: 3,
  variants: [],
  features: []
};

function Depiction({
  tagName,
  className,
  headingLevel,
  variants,
  children,
  features,
  ...otherProps
}) {
  const variantClasses = variants.map(variant => `Depiction--${variant}`);
  const classes = classNames(className, 'Depiction', variantClasses);
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  return (
    <TagName className={classes} {...otherProps}>
      <div className="Depiction__col">
        {features.map((feature, i) => (
          <div className="Depiction__content">
            <Heading className="Depiction__title">{feature.name}</Heading>
    
            {feature.text && <p className="Depiction__text">{feature.text}</p>}
          </div>
        ))}
      </div>
      <div className="Depiction__icon">
        <img
          alt='depiction illustration'
          src={'/img/' + 'getindata-big-data-workshops.png'}
          style={{'objectFit': 'none'}}
        />
      </div>
    </TagName>
  );
}

Depiction.propTypes = propTypes;
Depiction.defaultProps = defaultProps;

export default Depiction;
