import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import Button from './Button';

import IconArrowSrc from '../img/svg/icons/arrow-long-green-right.svg';

const propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tagName: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number
};

const defaultProps = {
  tagName: 'div',
  className: '',
  headingLevel: 3,
  isNewDate: false
};

function WorkshopItem({
  iconSrc,
  title,
  children,
  url,
  tagName,
  className,
  headingLevel,
  isNewDate
}) {
  const classes = classNames(className, 'WorkshopItem');
  const TagName = tagName;
  const Heading = `h${headingLevel}`;

  return (
    <TagName className={classes}>
      <div className="WorkshopItem__content">
        <img src={iconSrc} className="WorkshopItem__icon" alt={title} />

        {isNewDate
          ? (
            <div className="WorkshopItem__newDate">
              <div className="WorkshopItem__newDate__icon"></div>
              <div className="WorkshopItem__newDate__title">New date</div>
            </div>
          )
          : <div className="WorkshopItem__newDate WorkshopItem__newDate--empty"></div>
        }

        <Heading className="WorkshopItem__title">{title}</Heading>

        {children}
      </div>
      <div className="WorkshopItem__footer">
        <Button 
          className="WorkshopItem__link" 
          to={url}
          variants={['text']}
        >
          <div>See more</div>
          <SVG src={IconArrowSrc} />
        </Button>
        <Button className="WorkshopItem__btn" to={url}>
          See more
        </Button>
      </div>
    </TagName>
  );
}

WorkshopItem.propTypes = propTypes;
WorkshopItem.defaultProps = defaultProps;

export default WorkshopItem;
